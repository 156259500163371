import React from 'react';
import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import BlackButton from '../../atoms/BlackButton';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import numberWithSpaces from '../../../utils/numberWithSpaces';
import { Divider } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Accordion from '../../molecules/Accordion';
import ContactButton from '../../../contentful/content-types/ContactButton';

const maklareFeatureList = [
  {
    name: `Registrerad fastighetsmäklare`,
    content: (
      <Typography>
        Hos Privatmäklaren har du din egen, personliga mäklare. Mäklaren
        hanterar frågor om försäljningen, budgivning, regler och avtal. Din
        mäklare lotsar dig smidigt och tryggt genom hela försäljningsprocessen.
      </Typography>
    ),
  },
  {
    name: `Värdering`,
    content: (
      <>
        <Typography>
          Inför en försäljning gör vi en värdering av bostaden. Vi har lång
          erfarenhet och tillgång till verktyg för statistisk värdering.
        </Typography>
        <Typography>
          Din mäklare går igenom statistiken och värderingen med dig.
          Tillsammans kommer ni fram till ett lämpligt utgångspris.
        </Typography>
      </>
    ),
  },
  {
    name: `Fotografering`,
    content: (
      <Typography>
        Privatmäklaren har ett nätverk av bostadsfotografer i hela landet. Du
        får proffsbilder av högsta kvalité till din annons. För att bilderna
        skall bli så bra som möjligt är det viktigt att förbereda. Att "styla"
        sin bostad behöver inte vara komplicerat, men gör mycket för bilderna.
        Läs vår{' '}
        <Link href="/guide/fotografering-checklista">
          checklista för bostadsfotografering.
        </Link>
      </Typography>
    ),
  },
  {
    name: `Marknadsföring`,
    content: (
      <>
        <Typography>
          För att nå så många spekulanter som möjligt annonserar vi din bostad
          på alla viktiga bostadsportaler.
        </Typography>
        <ul>
          <li>Hemnet</li>
          <li>Booli</li>
          <li>Bovision</li>
          <li>Privatmäklaren</li>
        </ul>
        <Typography>
          Förutom annonsering på de stora portalerna kan vi rikta marknadsföring
          till rätt målgrupp för just din bostad genom sociala medier som
          Facebook och Instagram.
        </Typography>
      </>
    ),
  },
  {
    name: `Budgivning`,
    content: (
      <>
        <Typography>
          Budgivningen sköts av din mäklare med stöd av vårt trygga och
          effektiva budgivningssystem.
        </Typography>
        <Typography>
          Budgivare identifierar sig med BankID och bud meddelas automatiskt
          till dig, din mäklare och övriga budgivare via sms och mejl.
        </Typography>
      </>
    ),
  },
  {
    name: `Kontrakt/överlåtelseavtal`,
    content: (
      <>
        <Typography>
          När budgivningen är i mål och du bestämt dig för en köpare så är det
          dags att skriva avtal. Din mäklare samlar in information och sätter
          samman handlingarna. När alla eventuella frågor är utredda skriver
          parterna sedan under avtalen.
        </Typography>
      </>
    ),
  },
  {
    name: `Tillträde`,
    content: (
      <>
        <Typography>
          Tillträdet förbereds och hanteras av din mäklare. Mäklaren sköter all
          dokumentation och bokar in transaktionen med med bankerna. På
          tillträdesdagen ordnar mäklaren så att eventuella lån blir lösta och
          resterande pengar betalas ut till dig. Allt du behöver göra är att
          bestämma en tid för att lämna över nycklarna till köparen.
        </Typography>
      </>
    ),
  },
];
const SaljaContent = () => {
  return (
    <div>
      <Container maxWidth="lg" sx={{ mt: '20px' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', lg: 'row' },
            justifyContent: 'center',
            alignItems: { xs: 'center', lg: 'stretch' },
          }}
        >
          <Box
            sx={{
              maxWidth: '400px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              pb: '50px',
              padding: "1rem"
            }}
          >
            <Box
              sx={{
                width: '130px',
                height: '30px',
                backgroundColor: '#FBCEB1',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography
                sx={{ textTransform: 'uppercase', textAlign: 'center' }}
              >
                toppval
              </Typography>
            </Box>
            <Typography
              sx={{
                textTransform: 'uppercase',
                textAlign: 'center',
                fontSize: '30px',
                fontFamily: 'Avenir-Bold',
                mt: '15px',
              }}
            >
              Mäklare
            </Typography>
            <Box
              component="img"
              src="images/g1.svg"
              alt="g1"
              sx={{
                mt: '20px',
                width: 'auto',
              }}
            />
            <Box sx={{ mt: '20px', mb: 'auto' }}>
              <Box
                sx={{
                  my: '10px',
                  display: 'flex',
                }}
              >
                <CheckIcon sx={{ mt: '3px', mr: '25px' }} />
                <Typography
                  sx={{
                    fontSize: { lg: '20px', xs: '18px' },
                    fontFamily: 'Lato',
                  }}
                >
                  Registrerad fastighets-mäklare
                </Typography>
              </Box>
              <Box
                sx={{
                  my: '10px',
                  display: 'flex',
                }}
              >
                <CheckIcon sx={{ mt: '3px', mr: '25px' }} />
                <Typography
                  sx={{
                    fontSize: { lg: '20px', xs: '18px' },
                    fontFamily: 'Lato',
                  }}
                >
                  Bostadsvärdering
                </Typography>
              </Box>
              <Box
                sx={{
                  my: '10px',
                  display: 'flex',
                }}
              >
                <CheckIcon sx={{ mt: '3px', mr: '25px' }} />
                <Typography
                  sx={{
                    fontSize: { lg: '20px', xs: '18px' },
                    fontFamily: 'Lato',
                  }}
                >
                  Proffsfotograf
                </Typography>
              </Box>
              <Box
                sx={{
                  my: '10px',
                  display: 'flex',
                }}
              >
                <CheckIcon sx={{ mt: '3px', mr: '25px' }} />
                <Typography
                  sx={{
                    fontSize: { lg: '20px', xs: '18px' },
                    fontFamily: 'Lato',
                  }}
                >
                  Snygg och funktionell annons
                </Typography>
              </Box>
              <Box
                sx={{
                  my: '10px',
                  display: 'flex',
                }}
              >
                <CheckIcon sx={{ mt: '3px', mr: '25px' }} />
                <Typography
                  sx={{
                    fontSize: { lg: '20px', xs: '18px' },
                    fontFamily: 'Lato',
                  }}
                >
                  {`Annonsering på Hemnet, Booli, Bovision & Privatmäklaren *`}
                </Typography>
              </Box>
              <Box
                sx={{
                  my: '10px',
                  display: 'flex',
                }}
              >
                <CheckIcon sx={{ mt: '3px', mr: '25px' }} />
                <Typography
                  sx={{
                    fontSize: { lg: '20px', xs: '18px' },
                    fontFamily: 'Lato',
                  }}
                >
                  {`Visningsmaterial`}
                </Typography>
              </Box>
              <Box
                sx={{
                  my: '10px',
                  display: 'flex',
                }}
              >
                <CheckIcon sx={{ mt: '3px', mr: '25px' }} />
                <Typography
                  sx={{
                    fontSize: { lg: '20px', xs: '18px' },
                    fontFamily: 'Lato',
                  }}
                >
                  {`Budgivning`}
                </Typography>
              </Box>
              <Box
                sx={{
                  my: '10px',
                  display: 'flex',
                }}
              >
                <CheckIcon sx={{ mt: '3px', mr: '25px' }} />
                <Typography
                  sx={{
                    fontSize: { lg: '20px', xs: '18px' },
                    fontFamily: 'Lato',
                  }}
                >
                  {`Kontrakt/överlåtelseavtal`}
                </Typography>
              </Box>
              <Box
                sx={{
                  my: '10px',
                  display: 'flex',
                }}
              >
                <CheckIcon sx={{ mt: '3px', mr: '25px' }} />
                <Typography
                  sx={{
                    fontSize: { lg: '20px', xs: '18px' },
                    fontFamily: 'Lato',
                  }}
                >
                  {`Tillträde`}
                </Typography>
              </Box>
            </Box>

            <Typography>
              För bostadsrätt
            </Typography>
            <Typography
              sx={{
                fontSize: '30px',
                fontFamily: 'Avenir-Bold',
                mb: '10px',
              }}
            >
              {`${numberWithSpaces(19900)} kr`}
            </Typography>
            <Typography>
              För fastighet
            </Typography>
            <Typography
              sx={{
                fontSize: '30px',
                fontFamily: 'Avenir-Bold',
                mb: '10px',
              }}
            >
              {`${numberWithSpaces(23900)} kr`}
            </Typography>
            <BlackButton
              link="#maklare"
              width="190px"
              height="60px"
              name="Läs mer"
              fontSize="17px"
            />
          </Box>

          <Box
            sx={{
              width: '1.5px',
              backgroundColor: 'black',
              flexGrow: 0,
            }}
          />

          <Box
            sx={{
              maxWidth: '400px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              pb: '50px',
              padding: "1rem"
            }}
          >
            <Typography
              sx={{
                textTransform: 'uppercase',
                textAlign: 'center',
                fontSize: '30px',
                fontFamily: 'Avenir-Bold',
                mt: '45px',
              }}
            >
              Kontrakt
            </Typography>
            <Box
              component="img"
              src="images/g2.svg"
              alt="g1"
              sx={{
                mt: '20px',
                width: 'auto',
              }}
            />
            <Box sx={{ mt: '20px', mb: 'auto' }}>
              <Box
                sx={{
                  my: '10px',
                  display: 'flex',
                }}
              >
                <CheckIcon sx={{ mt: '3px', mr: '25px' }} />
                <Typography
                  sx={{
                    fontSize: { lg: '20px', xs: '18px' },
                    fontFamily: 'Lato',
                  }}
                >
                  Registrerad fastighets-mäklare
                </Typography>
              </Box>
              <Box
                sx={{
                  my: '10px',
                  display: 'flex',
                  color: '#CCC',
                }}
              >
                <CloseIcon sx={{ mt: '3px', mr: '25px' }} />
                <Typography
                  sx={{
                    fontSize: { lg: '20px', xs: '18px' },
                    fontFamily: 'Lato',
                  }}
                >
                  Bostadsvärdering
                </Typography>
              </Box>
              <Box
                sx={{
                  my: '10px',
                  display: 'flex',
                  color: '#CCC',
                }}
              >
                <CloseIcon sx={{ mt: '3px', mr: '25px' }} />
                <Typography
                  sx={{
                    fontSize: { lg: '20px', xs: '18px' },
                    fontFamily: 'Lato',
                  }}
                >
                  Proffsfotograf
                </Typography>
              </Box>
              <Box
                sx={{
                  my: '10px',
                  display: 'flex',
                  color: '#CCC',
                }}
              >
                <CloseIcon sx={{ mt: '3px', mr: '25px' }} />
                <Typography
                  sx={{
                    fontSize: { lg: '20px', xs: '18px' },
                    fontFamily: 'Lato',
                  }}
                >
                  Snygg och funktionell annons
                </Typography>
              </Box>
              <Box
                sx={{
                  my: '10px',
                  display: 'flex',
                  color: '#CCC',
                }}
              >
                <CloseIcon sx={{ mt: '3px', mr: '25px' }} />
                <Typography
                  sx={{
                    fontSize: { lg: '20px', xs: '18px' },
                    fontFamily: 'Lato',
                  }}
                >
                  {`Annonsering på Hemnet, Booli & Privatmäklaren`}
                </Typography>
              </Box>
              <Box
                sx={{
                  my: '10px',
                  display: 'flex',
                  color: '#CCC',
                }}
              >
                <CloseIcon sx={{ mt: '3px', mr: '25px' }} />
                <Typography
                  sx={{
                    fontSize: { lg: '20px', xs: '18px' },
                    fontFamily: 'Lato',
                  }}
                >
                  {`Visningsmaterial`}
                </Typography>
              </Box>
              <Box
                sx={{
                  my: '10px',
                  display: 'flex',
                  color: '#CCC',
                }}
              >
                <CloseIcon sx={{ mt: '3px', mr: '25px' }} />
                <Typography
                  sx={{
                    fontSize: { lg: '20px', xs: '18px' },
                    fontFamily: 'Lato',
                  }}
                >
                  {`Budgivning`}
                </Typography>
              </Box>
              <Box
                sx={{
                  my: '10px',
                  display: 'flex',
                }}
              >
                <CheckIcon sx={{ mt: '3px', mr: '25px' }} />
                <Typography
                  sx={{
                    fontSize: { lg: '20px', xs: '18px' },
                    fontFamily: 'Lato',
                  }}
                >
                  {`Kontrakt/överlåtelseavtal`}
                </Typography>
              </Box>
              <Box
                sx={{
                  my: '10px',
                  display: 'flex',
                }}
              >
                <CheckIcon sx={{ mt: '3px', mr: '25px' }} />
                <Typography
                  sx={{
                    fontSize: { lg: '20px', xs: '18px' },
                    fontFamily: 'Lato',
                  }}
                >
                  {`Tillträde`}
                </Typography>
              </Box>
            </Box>

            <Typography>
              För bostadsrätt
            </Typography>
            <Typography
              sx={{
                fontSize: '30px',
                fontFamily: 'Avenir-Bold',
                mb: '10px',
              }}
            >
              {`${numberWithSpaces(9900)} kr`}
            </Typography>
            <Typography>
              För fastighet
            </Typography>
            <Typography
              sx={{
                fontSize: '30px',
                fontFamily: 'Avenir-Bold',
                mb: '10px',
              }}
            >
              {`${numberWithSpaces(12900)} kr`}
            </Typography>
            <BlackButton
              link="#kontrakt"
              width="190px"
              height="60px"
              name="Läs mer"
              fontSize="17px"
            />
          </Box>

          {/*<Box
            sx={{
              width: '1.5px',
              backgroundColor: 'black',
              flexGrow: 0,
            }}
          />*/}

          {/*<Box
            sx={{
              maxWidth: '300px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              pb: '50px',
            }}
          >
            <Typography
              sx={{
                textTransform: 'uppercase',
                textAlign: 'center',
                fontSize: '30px',
                fontFamily: 'Avenir-Bold',
                mt: '45px',
              }}
            >
              Annons
            </Typography>
            <Box
              component="img"
              src="images/g3.svg"
              alt="g1"
              sx={{
                mt: '20px',
                width: 'auto',
              }}
            />
            <Box sx={{ mt: '20px', mb: 'auto' }}>
              <Box
                sx={{
                  my: '10px',
                  display: 'flex',
                  color: '#CCC',
                }}
              >
                <CloseIcon sx={{ mt: '3px', mr: '25px' }} />
                <Typography
                  sx={{
                    fontSize: '20px',
                    fontFamily: 'Lato',
                  }}
                >
                  Registrerad fastighets-mäklare
                </Typography>
              </Box>
              <Box
                sx={{
                  my: '10px',
                  display: 'flex',
                  color: '#CCC',
                }}
              >
                <CloseIcon sx={{ mt: '3px', mr: '25px' }} />
                <Typography
                  sx={{
                    fontSize: '20px',
                    fontFamily: 'Lato',
                  }}
                >
                  Bostadsvärdering
                </Typography>
              </Box>
              <Box
                sx={{
                  my: '10px',
                  display: 'flex',
                  color: '#CCC',
                }}
              >
                <CloseIcon sx={{ mt: '3px', mr: '25px' }} />
                <Typography
                  sx={{
                    fontSize: '20px',
                    fontFamily: 'Lato',
                  }}
                >
                  Proffsfotograf
                </Typography>
              </Box>
              <Box
                sx={{
                  my: '10px',
                  display: 'flex',
                }}
              >
                <CheckIcon sx={{ mt: '3px', mr: '25px' }} />
                <Typography
                  sx={{
                    fontSize: '20px',
                    fontFamily: 'Lato',
                  }}
                >
                  Snygg och funktionell annons
                </Typography>
              </Box>
              <Box
                sx={{
                  my: '10px',
                  display: 'flex',
                }}
              >
                <CheckIcon sx={{ mt: '3px', mr: '25px' }} />
                <Typography
                  sx={{
                    fontSize: '20px',
                    fontFamily: 'Lato',
                  }}
                >
                  {`Annonsering på Booli och Privatmäklaren`}
                </Typography>
              </Box>
              <Box
                sx={{
                  my: '10px',
                  display: 'flex',
                  color: '#CCC',
                }}
              >
                <CloseIcon sx={{ mt: '3px', mr: '25px' }} />
                <Typography
                  sx={{
                    fontSize: '20px',
                    fontFamily: 'Lato',
                  }}
                >
                  {`Visningsmaterial`}
                </Typography>
              </Box>
              <Box
                sx={{
                  my: '10px',
                  display: 'flex',
                  color: '#CCC',
                }}
              >
                <CloseIcon sx={{ mt: '3px', mr: '25px' }} />
                <Typography
                  sx={{
                    fontSize: '20px',
                    fontFamily: 'Lato',
                  }}
                >
                  {`Budgivning`}
                </Typography>
              </Box>
              <Box
                sx={{
                  my: '10px',
                  display: 'flex',
                  color: '#CCC',
                }}
              >
                <CloseIcon sx={{ mt: '3px', mr: '25px' }} />
                <Typography
                  sx={{
                    fontSize: '20px',
                    fontFamily: 'Lato',
                  }}
                >
                  {`Kontrakt/överlåtelseavtal`}
                </Typography>
              </Box>
              <Box
                sx={{
                  my: '10px',
                  display: 'flex',
                  color: '#CCC',
                }}
              >
                <CloseIcon sx={{ mt: '3px', mr: '25px' }} />
                <Typography
                  sx={{
                    fontSize: '20px',
                    fontFamily: 'Lato',
                    mb: 'auto',
                  }}
                >
                  {`Tillträde`}
                </Typography>
              </Box>
            </Box>

            <Typography
              sx={{
                fontSize: '30px',
                fontFamily: 'Avenir-Bold',
                my: '10px',
              }}
            >
              {`${numberWithSpaces(790)} kr`}
            </Typography>
            <BlackButton
              link="#annons"
              width="190px"
              height="60px"
              name="Läs mer"
              fontSize="17px"
            />
          </Box>*/}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            my: { xs: '30px', md: '63px' },
            height: { md: '394px', xs: '180px' },
            backgroundColor: 'rgba(49, 57, 76, 1)',
          }}
        >
          <Typography
            component="p"
            className="font-Lato-Bold"
            sx={{
              fontSize: { xs: '18px', md: '36px' },
              color: 'white',
            }}
          >
            Funderar du på att sälja din bostad?
          </Typography>
          <Typography
            component="p"
            className="font-Lato-Regular"
            sx={{
              fontSize: { xs: '14px', md: '24px' },
              marginTop: { md: '55px', xs: '9px' },
              marginBottom: { md: '72px', xs: '22px' },
              color: 'white',
            }}
          >
            Låt oss kontakta dig för en fri värdering.
          </Typography>
          <ContactButton title={'Kontakt'}></ContactButton>
        </Box>
      </Container>

      <Box sx={{ backgroundColor: '#83B898CC' }}>
        <Container maxWidth="lg">
          <Box
            sx={{
              py: '80px',
              display: 'flex',
              flexDirection: { xs: 'column-reverse', lg: 'row' },
            }}
          >
            <Box
              id="maklare"
              sx={{
                position: 'relative',
                width: { lg: '50%', xs: '100%' },
                flexGrow: 0,
                flexShrink: 0,
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: { lg: '-196px', xs: '-170px' },
                }}
                id="maklare"
              ></Box>
              <Typography
                sx={{
                  fontSize: { lg: '36px', xs: '24px' },
                  fontFamily: 'Avenir-Bold',
                  textTransform: 'uppercase',
                }}
              >
                Mäklare
              </Typography>
              <Typography
                sx={{
                  fontSize: { lg: '24px', xs: '18px' },
                  fontFamily: 'Avenir',
                  mt: '35px',
                }}
              >
                {`Så här går det till att sälja med Privatmäklaren`}
              </Typography>
              <Typography
                sx={{
                  fontSize: { lg: '18px', xs: '18px' },
                  fontFamily: 'Lato-Light',
                  mt: '35px',
                }}
              >
                {`Skillnaden mellan Privatmäklaren och en traditionell mäklare är
                att vårt arvode är mycket lägre och att du som säljare tar hand
                om visningarna. Och vem kan vara bättre lämpad än dig att sköta
                visningarna? Du vet ju allt om din bostad! Att sälja med
                Privatmäklaren innebär ett samarbete mellan dig som säljare och
                oss som mäklare. I vår modell gör alla det som de är bäst på:`}
              </Typography>
              <ul>
                <Typography
                  component="li"
                  sx={{
                    fontSize: { lg: '18px', xs: '18px' },
                    fontFamily: 'Lato-Light',
                    mt: '25px',
                  }}
                >
                  {`Fotografen fotograferar.`}
                </Typography>
                <Typography
                  component="li"
                  sx={{
                    fontSize: { lg: '18px', xs: '18px' },
                    fontFamily: 'Lato-Light',
                  }}
                >
                  {`Du som säljare sköter visningarna och kan svara på detaljfrågor om bostaden och området.`}
                </Typography>
                <Typography
                  component="li"
                  sx={{
                    fontSize: { lg: '18px', xs: '18px' },
                    fontFamily: 'Lato-Light',
                  }}
                >
                  {`Din mäklare hos Privatmäklaren sköter all marknadsföring, administration och dokumentation.`}
                </Typography>
              </ul>
              <Typography
                sx={{
                  fontSize: { lg: '18px', xs: '18px' },
                  fontFamily: 'Lato-Light',
                  mt: '25px',
                }}
              >
                {`Vi har delat upp vårt arvode i två delar:`}
              </Typography>
              <ul>
                <Typography
                  component="li"
                  sx={{
                    fontSize: { lg: '18px', xs: '18px' },
                    fontFamily: 'Lato-Light',
                    mt: '25px',
                  }}
                >
                  <b>4 900 kr</b> när vi påbörjar förmedlingen i samband med fotografering och annonsering.
                </Typography>
                <Typography
                  component="li"
                  sx={{
                    fontSize: { lg: '18px', xs: '18px' },
                    fontFamily: 'Lato-Light',
                  }}
                >
                  När affären är klar och ditt objekt är sålt (och bara om det blir sålt) så betalar du<br/>
                  <ul>
                    <li><b>15 000 kr</b> om du säljer en bostadsrätt</li>
                    <li><b>19 000 kr</b> om du säljer en fastighet</li>
                  </ul>
                </Typography>
              </ul>

              <Typography
                component="p"
                sx={{
                  fontSize: { lg: '18px', xs: '18px' },
                  fontFamily: 'Lato-Light',
                }}
              >
                {`* Utöver vårt arvode tar Hemnet ut en avgift. Avgiften kan du få fram direkt via `}
                <a href="https://www.hemnet.se/annonsera-bostad#priskalkylatorn">
                  HEMNETS ANNONSPRISKALKYLATOR
                </a>
              </Typography>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                maxWidth: { xs: '300px', lg: 'none' },
              }}
            >
              <img
                src={'/images/illustration_maklare.png'}
                width="100%"
                alt="illustration_maklare"
                loading="lazy"
              />
            </Box>
          </Box>

          <Typography
            sx={{
              fontSize: { lg: '24px', xs: '18px' },
              fontFamily: 'Avenir',
              textTransform: 'uppercase',
            }}
          >
            {`DetTA ingår`}
          </Typography>
          <Accordion data={maklareFeatureList} />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              pt: '50px',
              pb: '80px',
            }}
          >
            <BlackButton
              link="https://login.privatmaklaren.se/?#!/registrera"
              width="190px"
              height="60px"
              name="Starta tjänst"
              fontSize="17px"
            />
          </Box>
        </Container>
      </Box>

      <Box sx={{ backgroundColor: '#FBEBAA' }}>
        <Container maxWidth="lg">
          <Box
            sx={{
              py: '80px',
              display: 'flex',
              flexDirection: { xs: 'column', lg: 'row' },
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                maxWidth: { xs: '300px', lg: 'none' },
              }}
            >
              <img
                src={'/images/illustration_kontrakt.png'}
                width="100%"
                alt="illustration_maklare"
                loading="lazy"
              />
            </Box>
            <Box
              id="kontrakt"
              sx={{
                position: 'relative',
                width: { lg: '50%', xs: '100%' },
                flexGrow: 0,
                flexShrink: 0,
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: { lg: '-196px', xs: '-170px' },
                }}
                id="kontrakt"
              ></Box>
              <Typography
                sx={{
                  fontSize: { lg: '36px', xs: '24px' },
                  fontFamily: 'Avenir-Bold',
                  textTransform: 'uppercase',
                }}
              >
                Kontrakt
              </Typography>
              <Typography
                sx={{
                  fontSize: { lg: '24px', xs: '18px' },
                  fontFamily: 'Avenir',
                  mt: '35px',
                }}
              >
                {`Skrivuppdrag med mäklare`}
              </Typography>
              <Typography
                sx={{
                  fontSize: { lg: '18px', xs: '18px' },
                  fontFamily: 'Lato-Light',
                  mt: '30px',
                }}
              >
                {`Om du redan hittat en köpare till din bostad så kan en av våra mäklare hjälpa till att sätta samman all nödvändig dokumentation.`}
              </Typography>
              <Typography
                sx={{
                  fontSize: { lg: '18px', xs: '18px' },
                  fontFamily: 'Lato-Light',
                  mt: '25px',
                }}
              >
                {`Detta ingår i ett skrivuppdrag:`}
              </Typography>
              <ul>
                <Typography
                  component="li"
                  sx={{
                    fontSize: { lg: '18px', xs: '18px' },
                    fontFamily: 'Lato-Light',
                    mt: '20px',
                  }}
                >
                  {`En personlig mäklare som hanterar ert ärende`}
                </Typography>
                <Typography
                  component="li"
                  sx={{
                    fontSize: { lg: '18px', xs: '18px' },
                    fontFamily: 'Lato-Light',
                  }}
                >
                  {`Köpekontrakt/överlåtelseavtal med bilagor`}
                </Typography>
                <Typography
                  component="li"
                  sx={{
                    fontSize: { lg: '18px', xs: '18px' },
                    fontFamily: 'Lato-Light',
                  }}
                >
                  {`Hantering av handpenning`}
                </Typography>
                <Typography
                  component="li"
                  sx={{
                    fontSize: { lg: '18px', xs: '18px' },
                    fontFamily: 'Lato-Light',
                  }}
                >
                  {`I fallet med bostadsrätt, hantering av medlemskap i bostadsrättsföreningen`}
                </Typography>
                <Typography
                  component="li"
                  sx={{
                    fontSize: { lg: '18px', xs: '18px' },
                    fontFamily: 'Lato-Light',
                  }}
                >
                  {`Kontakt med köparens och säljarens respektive banker samt bokning av tillträde`}
                </Typography>
                <Typography
                  component="li"
                  sx={{
                    fontSize: { lg: '18px', xs: '18px' },
                    fontFamily: 'Lato-Light',
                  }}
                >
                  {`Tillträde med uppställning av avräkning för lösen av lån`}
                </Typography>
                <Typography
                  component="li"
                  sx={{
                    fontSize: { lg: '18px', xs: '18px' },
                    fontFamily: 'Lato-Light',
                  }}
                >
                  {`I fallet med fastighet även köpebrev och lagfartsansökan`}
                </Typography>
              </ul>
              <Typography
                sx={{
                  fontSize: { lg: '18px', xs: '18px' },
                  fontFamily: 'Lato-Light',
                  mt: '25px',
                }}
              >
                Du anmäler dig här, sedan kommer en av våra mäklare kontakta dig.
              </Typography>
              <Typography
                sx={{
                  fontSize: { lg: '18px', xs: '18px' },
                  fontFamily: 'Lato-Light',
                  mt: '25px',
                }}
              >
                Arvode för skrivuppdrag:
                <ul>
                  <li><b>9 900 kr</b> om du säljer en bostadsrätt</li>
                  <li><b>12 900 kr</b> om du säljer en fastighet</li>
                </ul>
              </Typography>
              <Box
                mt={5}
                sx={{
                  display: 'flex',
                  justifyContent: { xs: 'center', md: 'start' },
                }}
              >
                <BlackButton
                  width="190px"
                  link="https://login.privatmaklaren.se/?#!/registrera"
                  height="60px"
                  name="Starta tjänst"
                  fontSize="17px"
                />
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>

      {/*<Box sx={{ backgroundColor: '#83B89833' }}>
        <Container maxWidth="lg">
          <Box
            sx={{
              py: '80px',
              display: 'flex',
              flexDirection: { xs: 'column-reverse', lg: 'row' },
            }}
          >
            <Box
              id="annons"
              sx={{
                position: 'relative',
                width: { lg: '50%', xs: '100%' },
                flexGrow: 0,
                flexShrink: 0,
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: { lg: '-196px', xs: '-170px' },
                }}
                id="annons"
              ></Box>
              <Typography
                sx={{
                  fontSize: { lg: '36px', xs: '24px' },
                  fontFamily: 'Avenir-Bold',
                  textTransform: 'uppercase',
                }}
              >
                Annons
              </Typography>
              <Typography
                sx={{
                  fontSize: { lg: '24px', xs: '18px' },
                  fontFamily: 'Avenir',
                  mt: '35px',
                }}
              >
                {`Sälj din bostad utan mäklare`}
              </Typography>
              <Typography
                sx={{
                  fontSize: { lg: '18px', xs: '18px' },
                  fontFamily: 'Lato-Light',
                  mt: '30px',
                }}
              >
                {`Privatmäklarens Annonstjänst passar dig som vill prova att sälja din bostad utan mäklare.
I Annonstjänsten ingår annonsering på Privatmäklaren och på Booli.se. Booli är Sveriges näst största söktjänst för bostäder (efter Hemnet).`}
              </Typography>
              <Typography
                sx={{
                  fontSize: { lg: '18px', xs: '18px' },
                  fontFamily: 'Lato-Light',
                  mt: '25px',
                }}
              >
                {`Detta ingår i Annonstjänsten:`}
              </Typography>
              <Typography
                sx={{
                  fontSize: { lg: '18px', xs: '18px' },
                  fontFamily: 'Lato-Light',
                  mt: '20px',
                }}
              >
                {`• Lägg upp din egen annons på ett professionellt sätt`}
              </Typography>
              <Typography
                sx={{
                  fontSize: { lg: '18px', xs: '18px' },
                  fontFamily: 'Lato-Light',
                }}
              >
                {`• Vi går igenom din annons`}
              </Typography>
              <Typography
                sx={{
                  fontSize: { lg: '18px', xs: '18px' },
                  fontFamily: 'Lato-Light',
                }}
              >
                {`• Din annons publiceras på Privatmäklaren.se och Booli.se`}
              </Typography>
              <Typography
                sx={{
                  fontSize: { lg: '18px', xs: '18px' },
                  fontFamily: 'Lato-Light',
                }}
              >
                {`• Utrymme för så många bilder och dokument ni vill`}
              </Typography>
              <Typography
                sx={{
                  fontSize: { lg: '18px', xs: '18px' },
                  fontFamily: 'Lato-Light',
                }}
              >
                {`• Hjälp och guider via Privatmäklaren.se för att sälja privat`}
              </Typography>
              <Typography
                sx={{
                  fontSize: { lg: '18px', xs: '18px' },
                  fontFamily: 'Lato-Light',
                }}
              >
                {`• Support via E-mail`}
              </Typography>
              <Typography
                sx={{
                  fontSize: { lg: '18px', xs: '18px' },
                  fontFamily: 'Lato-Light',
                }}
              >
                {`• Lätt att (vid behov) byta över till vår `}
                  <Link href={"/salja#maklare"}>Mäklartjänst</Link>
              </Typography>
              <Typography
                sx={{
                  fontSize: { lg: '18px', xs: '18px' },
                  fontFamily: 'Lato-Light',
                  mt: '20px',
                }}
              >
                {`Du gör klart din annons, godkänner och betalar. Därefter publicerar vi. Din annons ligger ute i 3-månader eller tills du väljer att avpublicera den.`}
              </Typography>
              <Box
                mt={5}
                mb={10}
                sx={{
                  display: 'flex',
                  justifyContent: { xs: 'center', md: 'start' },
                }}
              >
                <BlackButton
                  link="https://login.privatmaklaren.se/?#!/registrera"
                  width="190px"
                  height="60px"
                  name="Starta tjänst"
                  fontSize="17px"
                />
              </Box>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                maxWidth: { xs: '300px', lg: 'none' },
              }}
            >
              <img
                src={'/images/illustration_annons.png'}
                width="100%"
                alt="illustration_maklare"
                loading="lazy"
              />
            </Box>
          </Box>
        </Container>
      </Box>*/}
    </div>
  );
};

export default SaljaContent;
